import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

const Callback = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const token = query.get('token');

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
            navigate('/');
        } else {
            localStorage.removeItem('token');
            navigate('/login');
        }
    }, [token]);

    return <div>Redirecting...</div>;
};

export default Callback;