import React, { useState, useEffect } from 'react';
import Layout from '@/components/ui/Layout'
import Nav from '@/components/Nav'

import Coaching from '@/views/Coaching'
import Playbook from '@/views/Playbook'
import Onboarding from '@/views/Onboarding'
import Library from '@/views/Library'
import Room from '@/views/Video'
import Breakout from '@/views/Breakout'
import Packages from '@/views/Packages';

import Chat from '@/views/Chat'
import Prework from '@/views/Prework'
import Content from '@/views/Content'
import Call from '@/views/Call'

import Admin from '@/views/admin/Admin'
import Teams from '@/views/admin/Teams'
import Team from '@/views/admin/Team'
import Lessons from '@/views/admin/Lessons'
import Calls from '@/views/admin/Calls'
import Resources from '@/views/admin/Resources';

import AskQuestion from '@/views/AskQuestion';
import ChatPage from '@/views/ChatPage';

import DocumentViewer from '@/components/DocumentViewer'

import { getRequest } from '@/utils'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import JSConfetti from 'js-confetti'

import { datadogLogs } from '@datadog/browser-logs';
import MyQuestions from '@/views/MyQuestions';
import Callback from '@/views/Callback';
import { postRequest } from './utils';

const jsConfetti = new JSConfetti()
window.confetti = jsConfetti
window.confetti.destroyCanvas()

window.confetti.add = (x) => {
  document.querySelector('body').append(window.confetti.canvas)
  window.confetti.addConfetti(x).then(() => {
    window.confetti.destroyCanvas()
  })
}

const App = () => {
  const [ready, setReady] = useState(false)
  console.log("testing....");
  useEffect(() => {
    const checkSession = async () => {
      try{
        const userToken = localStorage.getItem('token');
        if (window.location.pathname === '/callback') { 
          setReady(true);
          return
        }
        if (userToken) {
          const requestData = { userToken };
          const responseData = await postRequest('/connect', JSON.stringify(requestData));
          if(responseData && !responseData.loggedIn) {
            window.location.href = `${process.env.REACT_APP_API_URL}/login`
          }
        } else {
           window.location.href = `${process.env.REACT_APP_API_URL}/login`
        }
        setReady(true);
      }catch(e){
        console.log(e);
        window.location.href = `${process.env.REACT_APP_API_URL}/login`
      }
    }
    checkSession()
  }, [])

  return (
    <div className="layout">
      { ready &&
        <Router>
          <Layout>
            <RouteRender />
            <DocumentViewer></DocumentViewer>
          </Layout>
        </Router>
      }
    </div>
  );
};

// Login Page
// Home Page - Ask a question
// Chat Page - Chat with the AI
// Call Page - Call with the AI
// Settings Page - Settings
// Package Page - Package
// Pricing Page - Pricing
// Signup Page - Signup
// Login Page - Login
// Forgot Password Page - Forgot Password
// Reset Password Page - Reset Password

const RouteRender = () => {
  const location = useLocation();

  const MemoizedChat = React.memo(Chat);
  const MemoizedPrework = React.memo(Prework);
  const MemoizedContent = React.memo(Content);

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={300}
        classNames="fade"
      >
        <Routes location={location}>
          <Route path="/" exact={true} element={<AskQuestion />} />
          <Route path="/chat" exact={true} element={<ChatPage />} />
          <Route path="/call" exact={true} element={<Call />} />
          {/* <Route path="/settings" exact={true} element={<Settings />} /> */}
          <Route path="/packages" exact={true} element={<Packages />} />
          <Route path="/my-questions" exact={true} element={<MyQuestions />} />
          <Route path="/callback" exact={true} element={<Callback />} />
          {/* <Route path="/pricing" exact={true} element={<Pricing />} /> */}


          {/* <Route path="/" exact={true} element={<Playbook />} />
          <Route path="/video" exact={true} element={<Room />} />
          <Route path="/coaching" exact={true} element={<Coaching />} />

          <Route path="/video/breakout/:breakoutId" exact={true} element={<Breakout />} />
          <Route path="/enroll/:cohort" exact={false} element={<Onboarding />} />
          <Route path="/library" exact={false} element={<Library />} />

          <Route path="/lesson/:lessonId" exact={false} element={<MemoizedChat />} />
          <Route path="/prework/:lessonId" exact={false} element={<MemoizedPrework />} />
          <Route path="/content/:lessonId" exact={false} element={<MemoizedContent />} />


          <Route path="/admin" exact={true} element={<Admin/>} />

          <Route path="/admin/teams" exact={true} element={<Teams/>} />
          <Route path="/admin/team/:teamId" exact={true} element={<Team/>} />
          <Route path="/admin/lessons" exact={true} element={<Lessons/>} />
          <Route path="/admin/calls" exact={true} element={<Calls/>} />

          <Route path="/admin/resources" exact={true} element={<Resources/>} /> */}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};


export default App;
